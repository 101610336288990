import React from "react"

import moment from "moment"
import { Marker } from "react-map-gl"

import { VehicleData } from "types/vehicle"

import { getActiveTrip } from "./get-active-trip"
import { getBestGps } from "./get-best-gps"
import { VehicleIllustration } from "./vehicle-illustration"

interface VehicleProps {
  vehicle: VehicleData
  adminView: boolean
  selectVehicle: (vehicleId: number) => void
  deselected?: boolean
  highlighted?: boolean
}

const VehicleMarker = ({
  vehicle,
  adminView,
  selectVehicle,
  deselected = false,
  highlighted = false,
}: VehicleProps) => {
  const bestGpsValue = getBestGps(vehicle)

  function onClick() {
    selectVehicle(vehicle.id)
  }

  if (
    bestGpsValue !== null &&
    moment(bestGpsValue.gps.lastUpdated).isAfter(
      moment().subtract(1, "hour")
    ) &&
    (getActiveTrip(vehicle, adminView) != null || adminView)
  ) {
    return (
      <Marker
        longitude={bestGpsValue.gps.longitude}
        latitude={bestGpsValue.gps.latitude}
      >
        <div onClick={onClick}>
          <VehicleIllustration
            rotation={bestGpsValue.gps.heading - 90}
            deselected={deselected}
            highlighted={highlighted}
          />
        </div>
      </Marker>
    )
  } else {
    return null
  }
}

export { VehicleMarker }
