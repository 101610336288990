import { useEffect, useState } from "react"

/**
 * `useTabVisibility` returns a boolean value that indicates whether the tab displaying our code is
 * currently visible
 */
export function useTabVisibility(): boolean {
  const [isTabVisible, setTabVisible] = useState<boolean>(true)

  useEffect(() => {
    const listener = () => {
      setTabVisible(document.visibilityState == "visible")
    }
    document.addEventListener("visibilitychange", listener)
    return () => {
      document.removeEventListener("visibilitychange", listener)
    }
  }, [])

  return isTabVisible
}
