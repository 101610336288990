import moment from "moment-timezone"

import { LocationTime } from "types/location-time"
import { VehicleData, VehicleTrip } from "types/vehicle"

function getActiveTrip(
  vehicle: VehicleData,
  noLimit = false
): VehicleTrip | null {
  const previousTrip =
    vehicle.previousTrip == null ? null : sortTrip(vehicle.previousTrip)

  // Check previous trip has not ended (i.e. if it's the active trip)
  if (
    previousTrip != null &&
    previousTrip.route.length > 0 &&
    previousTrip.route[previousTrip.route.length - 1].arrival?.actual == null &&
    (previousTrip.type == "public" || previousTrip.type == "private_hire")
  ) {
    return previousTrip
  } else {
    const nextTrip =
      vehicle.nextTrip == null ? null : sortTrip(vehicle.nextTrip)
    if (
      nextTrip != null &&
      (nextTrip.type == "public" || nextTrip.type == "private_hire")
    ) {
      const nextTripDeparture = moment(nextTrip.route[0].departure?.scheduled)
      if (nextTripDeparture > moment().add(30, "minute") && noLimit == false) {
        return null
      } else {
        return nextTrip
      }
    } else {
      return null
    }
  }
}

function sortTrip(trip: VehicleTrip): VehicleTrip {
  const mutableTrip = trip
  const sortedRoute = mutableTrip.route.sort(
    (a: LocationTime, b: LocationTime) => {
      return a.departure.scheduled < b.departure.scheduled ? -1 : 1
    }
  )
  mutableTrip.route = sortedRoute
  return mutableTrip
}

export { getActiveTrip, sortTrip }
