import React from "react"

import { Popup } from "react-map-gl"

import { Separator, Stack, Text, TextLink } from "@kiwicom/orbit-components"
import { NewWindow } from "@kiwicom/orbit-components/icons"

import { StopPatternType } from "types/gatsby"

import { getGoogleMapLink } from "utils/url-utils"

import { StyledPopup } from "./styled-popup"

interface ActiveStopPopupProps {
  activeStopPatternItem: StopPatternType
  fullStopData: boolean
  onClose: () => void
}

const ActiveStopPopup = ({
  activeStopPatternItem,
  fullStopData,
  onClose,
}: ActiveStopPopupProps) => (
  <Popup
    key={`stop-popup-${activeStopPatternItem.stop.stopId}`}
    latitude={activeStopPatternItem.stop.coordinate[1]}
    longitude={activeStopPatternItem.stop.coordinate[0]}
    closeButton={false}
    onClose={onClose}
  >
    {/* Setting tabIndex makes element focusable which means link is underlined on open */}
    <StyledPopup tabIndex={0}>
      <Stack spacing="XSmall">
        <Stack spacing="XXXSmall">
          <Text weight="medium">{activeStopPatternItem.stop.stopName}</Text>
          <Text type="secondary">{activeStopPatternItem.stop.regionName}</Text>
        </Stack>
        <Separator spaceAfter="none" />
        <Stack spacing="XXSmall">
          {activeStopPatternItem.stop.description && (
            <Text size="small">{activeStopPatternItem.stop.description}</Text>
          )}
          {activeStopPatternItem.dropOffOnly && (
            <Text size="small">
              Drop-off only. The bus will not pick up here.
            </Text>
          )}
          {activeStopPatternItem.preBooked && (
            <Text size="small">
              Pre-booked only. Tickets must be booked online at least 10 minutes
              before the scheduled departure time.
            </Text>
          )}
          {activeStopPatternItem.description && (
            <Text size="small">{activeStopPatternItem.description}</Text>
          )}
          {fullStopData && (
            <>
              <Text size="small">
                Stop Name: {activeStopPatternItem.stop.stopName}
              </Text>
              <Text size="small">
                ATCO Code: {activeStopPatternItem.stop.atcoCode}
              </Text>
            </>
          )}
          <TextLink
            href={getGoogleMapLink(
              activeStopPatternItem.stop.coordinate[1],
              activeStopPatternItem.stop.coordinate[0],
              activeStopPatternItem.stop.googlePlaceId
            )}
            external
            size="small"
            iconRight={
              <NewWindow ariaLabel="Opens in new window" size="small" />
            }
          >
            View on Google Maps
          </TextLink>
        </Stack>
      </Stack>
    </StyledPopup>
  </Popup>
)

export { ActiveStopPopup }
