import React from "react"

import styled, { css } from "styled-components"

import BusSVG from "assets/bus-icon.svg"

interface VehicleWrapperProps {
  rotation: number
  scale: number
  deselected: boolean
  highlighted: boolean
}

const VehicleWrapper = styled.div<VehicleWrapperProps>`
  margin-top: ${(props) => `${props.scale * -7.1}px`};
  margin-left: ${(props) => `${props.scale * -20}px`};
  width: ${(props) => `${props.scale * 40}px`};
  cursor: pointer;
  position: absolute;
  line-height: 1;
  transform: ${(props) => `rotate(${props.rotation}deg)`};
  ${(props) =>
    props.deselected &&
    css`
      opacity: 0.9;
      filter: brightness(1.2);
    `}
  ${(props) =>
    props.highlighted &&
    css`
      transform: scale(1.2);
      .grow {
        transition: all 1s ease-in-out;
      }
      #vehicle-body {
        fill: green;
      }
      z-index: 100;
    `}
`

interface VehicleType {
  rotation?: number
  scale?: number
  deselected?: boolean
  highlighted?: boolean
}

const VehicleIllustration = ({
  rotation = 0,
  scale = 1,
  deselected = false,
  highlighted = false,
}: VehicleType) => {
  return (
    <VehicleWrapper
      rotation={rotation}
      scale={scale}
      deselected={deselected}
      highlighted={highlighted}
    >
      <BusSVG />
    </VehicleWrapper>
  )
}

export { VehicleIllustration }
