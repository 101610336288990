// @ts-strict-ignore
import React from "react"

import moment from "moment-timezone"
import { Popup } from "react-map-gl"
import TimeAgo from "timeago-react"

import {
  Grid,
  Heading,
  Separator,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import { InformationCircle } from "@kiwicom/orbit-components/icons"

import { LocationRow, Time } from "components/journey-illustration"

import { LocationTime } from "types/location-time"
import { VehicleData } from "types/vehicle"

import { formatAsTime, getDefaultTimezone } from "utils/date-utils"

import { getActiveTrip, sortTrip } from "./get-active-trip"
import { getBestGps } from "./get-best-gps"
import { StyledPopup } from "./styled-popup"

interface VehicleInfoProps {
  adminView: boolean
  activeVehicle: VehicleData
  onClose: () => void
}

const VehicleInfo = ({
  adminView,
  activeVehicle,
  onClose,
}: VehicleInfoProps) => {
  function getServiceDescription() {
    const activeTrip = getActiveTrip(activeVehicle, adminView)
    if (activeTrip == null) {
      return "Out of service"
    }

    // We take the origin, departure and scheduled departure time from the
    // `timetableSummary`, so that even if the first stop(s) on the route are
    // cancelled (and hence taken out of `activeTrip.route`) we can still
    // identify this trip as "The 10:30 Dundee to Glasgow"
    const tripTime = formatAsTime(
      activeTrip.timetableSummary.scheduledDeparture,
      getDefaultTimezone()
    )
    const fromName = activeTrip.timetableSummary.originRegionName
    const toName = activeTrip.timetableSummary.destinationRegionName
    return `${tripTime} from ${fromName} to ${toName}`
  }

  const nextStopActiveVehicle = nextStop(activeVehicle)

  return (
    <Popup
      key={`vehicle-popup-${activeVehicle.id}`}
      latitude={getBestGps(activeVehicle)?.gps.latitude}
      longitude={getBestGps(activeVehicle)?.gps.longitude}
      closeButton={false}
      onClose={onClose}
      style={{ pointerEvents: "none" }}
    >
      <StyledPopup>
        <Stack spacing="small" spaceAfter="smallest">
          <Stack spacing="XXSmall">
            <Heading type="title3">
              {adminView
                ? `${activeVehicle.plateNumber}`
                : getServiceDescription()}
            </Heading>
            {adminView && <Text size="small">{getServiceDescription()}</Text>}
            <Text size="small">
              Location updated{" "}
              <RoundedTimeAgo
                time={getBestGps(activeVehicle)?.gps.lastUpdated}
              />
              {adminView &&
                (getBestGps(activeVehicle)?.source == "secondary"
                  ? " (secondary GPS)"
                  : " (primary GPS)")}
            </Text>
          </Stack>
          {nextStopActiveVehicle == null ? null : (
            <>
              <Separator />
              <Stack spacing="XSmall">
                <Text weight="bold" size="small">
                  Next Stop
                </Text>
                <Grid
                  columns="max-content 1fr"
                  columnGap="8px"
                  rows="min-content"
                >
                  <Time
                    time={nextStopActiveVehicle.departure}
                    type="destination"
                    cancelled={false}
                    adminView={adminView}
                    enableTooltips={false}
                    timezoneContext={getDefaultTimezone()}
                  />
                  <LocationRow
                    location={nextStopActiveVehicle.location}
                    detailedView={false}
                  />
                </Grid>
              </Stack>
            </>
          )}
          {activeVehicle.isBackupVehicle && (
            <>
              <Separator />
              <Stack direction="row" spacing="small" align="center">
                <InformationCircle color="info" />
                <Text>
                  Operating with a backup{" "}
                  {activeVehicle.type == null ? "vehicle" : activeVehicle.type}
                  {activeVehicle.brand == null
                    ? ""
                    : ` (with ${activeVehicle.brand} branding)`}
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      </StyledPopup>
    </Popup>
  )
}

function nextStop(vehicle: VehicleData): LocationTime | null {
  const activeTrip = getActiveTrip(vehicle, true)
  if (activeTrip) {
    return sortTrip(activeTrip).route.filter(
      (stop) => stop.departure.actual == null && !stop.skipped
    )[0]
  } else {
    return null
  }
}

const RoundedTimeAgo = ({ time }) => {
  const momentTime = moment(time)
  const now = moment()
  const diff = now.second() - momentTime.second()
  const remainder = diff % 5
  return (
    <TimeAgo
      datetime={moment(time).add(remainder, "seconds").toISOString()}
      opts={{ relativeDate: now.toISOString() }}
    />
  )
}

export { VehicleInfo }
