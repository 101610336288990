// @ts-strict-ignore
import React, { useContext, useEffect, useRef, useState } from "react"

import { ResizeObserver } from "@juggle/resize-observer"
import center from "@turf/center"
import { featureCollection, point } from "@turf/helpers"
import { LineLayout } from "mapbox-gl"
import MapGL, { Layer, Source } from "react-map-gl"
import useMeasure from "react-use-measure"
import styled, { ThemeContext } from "styled-components"

import { fetchVehicles } from "api/vehicles"

import EmberGeolocateControl from "components/generic/map/ember-geolocate-control"
import EmberNavigationControl from "components/generic/map/ember-navigation-control"
import EmberScaleControl from "components/generic/map/ember-scale-control"
import Layout from "components/layout"
import { ActiveStopPopup } from "components/route-map/active-stop-popup"
import { MapWrapper } from "components/route-map/map-styles"
import { StopMarker } from "components/route-map/stop-marker"
import { VehicleInfo } from "components/route-map/vehicle-info"
import { VehicleMarker } from "components/route-map/vehicle-marker"

import { StopPatternType } from "types/gatsby"
import { Unsubscribable } from "types/observable"
import { VehicleData } from "types/vehicle"

import { useTabVisibility } from "utils/visibility-utils"

const FullPageWrapper = styled.div`
  height: 300px;
`
const RouteMapWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 300px;
`

export default (props) => {
  return (
    <FullPageWrapper>
      <Layout
        title="Map"
        description="Track your bus, check out our route map and find out exactly where we stop"
        includeFooter={true}
      >
        <RouteMapWrapper>
          <AudienceRouteMap audience={props.params.audience} />
        </RouteMapWrapper>
      </Layout>
    </FullPageWrapper>
  )
}

type AudienceRouteMapProps = {
  audience: string
}

const AudienceRouteMap = (props: AudienceRouteMapProps) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const isTabVisible = useTabVisibility()
  const [vehicles, setVehicles] = useState<VehicleData[]>([])
  const [activeVehicle, setActiveVehicle] = useState<VehicleData | null>(null)
  const [stops, setStops] = useState<StopPatternType[]>([])
  const [activeStopPatternItem, setActiveStopPatternItem] = useState<any>(null)
  const [routes, setRoutes] = useState<GeoJSON.Feature[]>([])
  const theme = useContext(ThemeContext)
  const [foundRouteCenter, setFoundRouteCenter] = useState<boolean>(false)
  const foundCenterRef = useRef(foundRouteCenter)
  const [viewState, setViewState] = useState({
    latitude: 56.181762205033635,
    longitude: -3.272838804652746,
    zoom: 8,
  })

  const lineLayout: LineLayout = {
    "line-cap": "round",
    "line-join": "round",
  }

  const linePaint = {
    "line-color": theme.orbit.paletteProductNormal,
    "line-width": 5,
    "line-opacity": 0.9,
  }

  let vehicleSubscription: Unsubscribable
  let timeout: NodeJS.Timeout

  function refreshVehicles() {
    vehicleSubscription = fetchVehicles({
      options: { audience: props.audience },
      onSuccess: (vehicles: VehicleData[]) => {
        setVehicles(vehicles)
        setStops(mapTripStops(vehicles))
        setRoutes(mapTripRoute(vehicles))
        if (isTabVisible) {
          timeout = setTimeout(refreshVehicles, 1000)
        }

        if (!foundCenterRef.current) {
          const points = vehicles.flatMap((vehicle) => {
            if (!("nextTrip" in vehicle) && !("previousTrip" in vehicle)) {
              return []
            }
            const trip = vehicle.nextTrip ?? vehicle.previousTrip
            if (!trip) {
              return []
            }
            return trip.route.map((lt) =>
              point([lt.location.lon, lt.location.lat])
            )
          })
          if (points.length > 0) {
            const centerPoint = center(featureCollection(points))
            setViewState({
              ...viewState,
              latitude: centerPoint.geometry.coordinates[1],
              longitude: centerPoint.geometry.coordinates[0],
            })
            setFoundRouteCenter(true)
            foundCenterRef.current = true
          }
        }
      },
      onError: () => {
        if (isTabVisible) {
          timeout = setTimeout(refreshVehicles, 1000)
        }
      },
    })
  }

  useEffect(() => {
    refreshVehicles()
  }, [])

  useEffect(() => {
    if (isTabVisible) {
      refreshVehicles()
    }
    return () => {
      vehicleSubscription?.unsubscribe()
      clearTimeout(timeout)
    }
  }, [isTabVisible])

  const mapTripStops = (vehicles: VehicleData[]): StopPatternType[] => {
    return vehicles.flatMap((vehicle) => {
      if (!("nextTrip" in vehicle) && !("previousTrip" in vehicle)) {
        return []
      }
      const trip = vehicle.nextTrip ?? vehicle.previousTrip

      return trip.route.map<StopPatternType>((point) => {
        return {
          dropOffOnly: false,
          preBooked: false,
          cutOffMins: 0,
          viaPartnerConnection: false,
          description: point.location.description,
          stop: {
            stopId: point.id,
            name: point.location.name,
            stopName: point.location.detailedName,
            description: point.location.description,
            regionName: point.location.regionName,
            coordinate: [point.location.lon, point.location.lat],
            googlePlaceId: point.location.googlePlaceId,
            atcoCode: point.location.atcoCode,
          },
        }
      })
    })
  }

  const mapTripRoute = (vehicles: VehicleData[]): GeoJSON.Feature[] => {
    return vehicles.flatMap((vehicle) => {
      if (!("nextTrip" in vehicle) && !("previousTrip" in vehicle)) {
        return []
      }
      const trip = vehicle.nextTrip ?? vehicle.previousTrip

      return {
        id: trip.id,
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: trip.route.map((point) => {
            return [point.location.lon, point.location.lat]
          }),
        },
        properties: {},
      }
    })
  }

  const selectStop = (stopPatternItem: StopPatternType) => {
    setActiveVehicle(null)
    setActiveStopPatternItem(stopPatternItem)
  }

  const selectVehicle = (vehicleId: number) => {
    setActiveStopPatternItem(null)
    const vehicle = vehicles.find((v) => v.id === vehicleId)
    setActiveVehicle(vehicle)
  }

  return (
    <MapWrapper ref={ref}>
      {bounds.width != 0 && ( // i.e. wait until width is showing as greater than 0 before loading map
        <MapGL
          {...viewState}
          onMove={(e) => setViewState(e.viewState)}
          mapboxAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
          mapStyle="mapbox://styles/mapbox/outdoors-v11?optimize=true"
        >
          <EmberGeolocateControl />
          <EmberNavigationControl />
          <EmberScaleControl />
          {routes.map((route) => (
            <Source
              key={`route-${route.id}`}
              id={`route-${route.id}`}
              type="geojson"
              data={route}
            >
              <Layer type="line" layout={lineLayout} paint={linePaint} />
            </Source>
          ))}
          {stops.map((stopPatternItem) => (
            <StopMarker
              key={stopPatternItem.stop.stopId}
              stopPatternItem={stopPatternItem}
              type={"primary"}
              selectStop={selectStop}
            />
          ))}
          {vehicles.map((vehicle) => (
            <VehicleMarker
              key={vehicle.id}
              adminView={false}
              selectVehicle={selectVehicle}
              vehicle={vehicle}
            />
          ))}
          {activeVehicle &&
            (activeVehicle.gps != null ||
              activeVehicle.secondaryGps != null) && (
              <VehicleInfo
                activeVehicle={activeVehicle}
                adminView={false}
                onClose={() => setActiveVehicle(null)}
              />
            )}
          {activeStopPatternItem && (
            <ActiveStopPopup
              activeStopPatternItem={activeStopPatternItem}
              fullStopData={false}
              onClose={() => setActiveStopPatternItem(null)}
            />
          )}
        </MapGL>
      )}
    </MapWrapper>
  )
}
