import React from "react"

import { Marker } from "react-map-gl"

import { StopPatternType } from "types/gatsby"

import { SecondaryStopMarkerIcon, StopMarkerIcon } from "./map-styles"

interface StopMarkerProps {
  stopPatternItem: StopPatternType
  type: "primary" | "secondary"
  selectStop: (stop: StopMarkerProps["stopPatternItem"]) => void
}

const StopMarker = ({ stopPatternItem, type, selectStop }: StopMarkerProps) => {
  const stop = stopPatternItem.stop

  function onClick() {
    selectStop(stopPatternItem)
  }

  return (
    <Marker longitude={stop.coordinate[0]} latitude={stop.coordinate[1]}>
      {type == "primary" ? (
        <StopMarkerIcon onClick={onClick} />
      ) : (
        <SecondaryStopMarkerIcon onClick={onClick} />
      )}
    </Marker>
  )
}

export { StopMarker }
